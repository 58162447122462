.page_404 {
    padding: 40px 0;
    background: #fff;
    font-family: 'Roboto';
}

.page_404 img {
    width: 100%;
}

.four_zero_four_bg {
    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
    max-width: 700px;
    margin: auto;
}

.four_zero_four_bg h1 {
    font-size: 80px;
}

.four_zero_four_bg h3 {
    font-size: 80px;
}

.contant_box_404 {
    margin-top: -50px;
    padding-left: 15px;
    padding-right: 15px;
}